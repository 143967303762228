<template>
  <div class="b2c-page-bg">  
    <div>
      <div class="fs-sp">
        <div class="w-100 b2c-bg">
          <div class="w-100 b2c-bg-top">
            <div class="b2c-top-left"></div>
            <div class="b2c-top-center">Vietlott</div>
            <div class="b2c-top-right"></div>
          </div>
        </div>
        <div class="w-100">
        </div>
      </div>
      <div class=" fs-pc">
        <div class="b2c-pc-header">
          <h2>VIETLOTT</h2>
          <div class="nav"></div>
        </div>
      </div>
    </div>
    <b-modal body-class="p-2" hide-header hide-footer id="modal-vietlott" title="Thông báo!">
      <div class="row">
        <div class="col-12">
          <h5 class="modal-title">Thông báo</h5>
          <p class="text text-center vietlott-info">Quý khách sẽ được chuyển đến ứng dụng cung cấp dịch vụ Mua hộ vé số Vietlott của Công ty Cổ phần Đầu tư 2B Việt Nam, Bằng việc bấm "<b>Tiếp tục</b>", Quý Khách cam kết đã đọc, hiểu và đồng ý với <b role="button" @click="$bvModal.show('modal-agree')">Nội dung điều khoản trước khi sử dụng dịch vụ</b></p>
        </div>
        <div class="col-12 text-center pt-2 mt-1 mb-3">
          <button class="fs-but1-white mr-1" @click="$bvModal.hide('modal-vietlott');$router.push({ path: '/' });">Đóng</button>
          <button class="fs-but1" @click="$bvModal.hide('modal-vietlott');$bvModal.show('modal-phone')">Tiếp tục</button>
        </div>
      </div>
    </b-modal>
    <b-modal body-class="p-3 bg-white" hide-header hide-footer id="modal-phone" title="Thông báo!">
      <div class="row">
        <div class="col-12">
          <h5 class="modal-title mb-2">Nhập số điện thoại của Quý khách</h5>
        </div>
        <div class="col-12">
          <b-form-input class="" v-model="phone" placeholder="Nhập số điện thoại"/>
        </div>
        <div class="col-lg-6 col-sm-12 pt-3">
          <b-form-input class="" v-model="otp" placeholder="Nhập mã OTP"/>
        </div>
        <div class="col-lg-6 col-sm-12 pt-3">
          <button class="btn btn-block btn-primary" @click="sendOTP">Lấy mã OTP</button>
        </div>
        <div class="col-12 text-center pt-3 mt-3 mb-3 border-top">
          <button class="fs-but1-white mr-1" @click="$bvModal.hide('modal-phone');$router.push({ path: '/' });">Đóng</button>
          <button class="fs-but1" @click="getVietlottView">Tiếp tục</button>
        </div>
      </div>
    </b-modal>
    <b-modal body-class="p-2" hide-header hide-footer id="modal-agree" title="Thông báo!">
      <div class="row">
        <div class="col-12">
          <h5 class="modal-title">Điều Khoản Vietlott</h5>
          <p class="text text-center vietlott-info">Trước khi sử dụng dịch vụ, Quý khách cam kết đã đọc, hiểu và đồng ý với nội dung sau:</p>
          <p class="text vietlott-info">
            <ul>
              <li>1. Công ty cổ phần Đầu tư 2B Việt Nam ("2B") là đơn vị có tư cách pháp nhân độc lập và không phải là công ty con, công ty liên kết, chi nhánh hay đơn vị trực thuộc của iTel;</li>
              <li>2. Ứng dụng do 2B độc lập phát triển và cung cấp cho khách hàng mà không phải là một phần hay bộ phận của ứng dụng My iTel;</li>
              <li>3. Đối với các vấn đề phát sinh, khiếu nại của khách hàng, 2B sẽ có trách nhiệm giải quyết.</li>
              <li>4. Quý khách nếu cần hỗ trợ vui lòng liên hệ Hotline 2B: 1900.88.68.48, Email: cskh@luckyluke.vn</li>
            </ul>
          </p>
        </div>
        <div class="col-12 text-center pt-2 mt-1">
          <button class="fs-but1-white mr-1" @click="$bvModal.hide('modal-agree')">Đóng</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import useJwt from "@/auth/jwt/useJwt";
import {
  BFormInput
} from "bootstrap-vue";
// Import Swiper styles
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination]);
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
  });
  return images;
}
const images = importAll(
  require.context("@/assets/images/ve-itel/", false, /.(png)$/)
);

export default {
  components: {
    Swiper,
    SwiperSlide,
    BFormInput
  },
  data() {
    return {
      iframeSrc: '',
      phone:'',
      otp:''
    };
  },
  watch: {
    $route(to, from) {
      console.log('a');
      this.$bvModal.show('modal-vietlott');
    },
  },
  created() {

  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted() {
    this.$bvModal.show('modal-vietlott');
  },
  methods: {
    Img(pic) {
      return images[pic];
    },
    getVietlottView(){
      if (!/^([0-9]{10})$/.test(this.phone)) {
        this.$toast.error("Vui lòng nhập đúng số thuê bao di động", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (!/^([0-9]{6})$/.test(this.otp)) {
        this.$toast.error("Mã OTP gồm 6 chũ số", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      useJwt.get("vietlot/getlink", {params:{phone:this.phone,tran_id:'VL-'+Date.now(),otp:this.otp}})
        .then((response) => {
          console.log(response.data.result)
          this.$bvModal.hide('modal-vietlott');
          
          // window.open(response.data.result);
          window.location.assign(response.data.result)
        }).catch((err) => {
          this.loading(false);
          this.$toast.error("Vui lòng kiểm tra số điện thoại và mã OTP", {
            icon: true,
            closeButton: "button",
          });
        });;
    },
    async sendOTP() {
      if (this.otp.length > 0) {
        this.otp = "";
      }
      if (!/^([0-9]{10})$/.test(this.phone)) {
        this.$toast.error("Vui lòng nhập đúng số thuê bao di động", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      this.loading(true);
      useJwt.get("otp2/" + this.phone).then((response) => {
          this.$toast.info("Vui lòng kiểm tra tin nhắn để lấy mã OTP", {
            icon: true,
            closeButton: "button",
          });
          this.isDisableOTPText = false;
          this.loading(false);
        }).catch((err) => {
          this.loading(false);
          this.$toast.error(err.response.data.message, {
            icon: true,
            closeButton: "button",
          });
        });
    },
  },
};
</script>
<style>
.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}
.b2c-bg {
  background: rgba(237, 31, 36, 1);
  padding-bottom: 32px;
}
.b2c-bg-top {
  background: rgba(237, 31, 36, 1);
  display: flex;
  margin-bottom: 0px;
}
.b2c-top-left::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOCAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDIwLjE0MjhMMTEgMTQuMTQyOEwxNyA4LjE0MjgyIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}
.b2c-top-left {
  height: 44px;
  padding: 8px 14px;
  width: 20%;
}
.b2c-pc-header {
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 500px;
}
.b2c-pc-header {
  display: flex;
}
.b2c-pc-header h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 69px;
  color: #424242;
  width: 50%;
}
.b2c-pc-header .nav{
  background-image:none !important
}
.b2c-top-center {
  width: 80%;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding-top: 7px;
}
.b2c-top-right {
  width: 20%;
}
.modal-title{
  padding-top: 35px;
  font-size: 30px !important;
  color: '#373737' !important;
  font-weight: 700;
  text-align: center;
}
.vietlott-info{
  line-height: 1.5;
  padding: 10px 30px
}
.fs-but1 {
	padding:0.8rem 2.55rem;
	background-color:#ff2424;
	/*text-transform:uppercase;*/
	font-size:1.8rem;
	font-weight:500;
	color:#fff !important;
	-webkit-border-radius:2rem;
	border-radius:2rem;
	border:2px solid rgba(255, 36, 36, 0);
	cursor:pointer;
	text-align:center;
}
.fs-but1-white {
	padding:0.8rem 4.55rem;
	background-color:#fff;
	font-size:1.8rem;
	font-weight:500;
	color:#ff2424 !important;
	-webkit-border-radius:2rem;
	border-radius:2rem;
	border:2px solid rgba(255, 36, 36, 1);
	cursor:pointer;
	text-align:center;
}
</style>